// variables
$loading-card-bg : rgba(77, 89, 149, 0.06);

// mixins

// Loading Card CSS
.loading-card {
    padding: 0 0 2px 0;
    position: relative;
    z-index: 0;
    left: 4px;
    .shine{
        background-color:$loading-card-bg;
        height: 65px;
        border-radius: 6px;
    }
}
