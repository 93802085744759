.kt-wizard-v2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 768px) {
    .kt-wizard-v2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; } }
  .kt-wizard-v2 .kt-wizard-v2__aside {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 400px;
    border-right: 1px solid #eeeef4;
    padding: 4.5rem 2.5rem 4.5rem 1.5rem; }
    .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav {
      width: 100%; }
      .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item {
          padding: 0.75rem 1.5rem;
          position: relative;
          border-radius: 0.5rem; }
          .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item .kt-wizard-v2__nav-body {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex; }
            .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item .kt-wizard-v2__nav-body .kt-wizard-v2__nav-icon {
              font-size: 2.5rem;
              margin-right: 1.1rem;
              color: #959cb6; }
            .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item .kt-wizard-v2__nav-body .kt-wizard-v2__nav-label {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center; }
              .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item .kt-wizard-v2__nav-body .kt-wizard-v2__nav-label .kt-wizard-v2__nav-label-title {
                color: #50566a;
                font-weight: 500;
                font-size: 1.1rem; }
              .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item .kt-wizard-v2__nav-body .kt-wizard-v2__nav-label .kt-wizard-v2__nav-label-desc {
                color: #959cb6; }
          .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item[data-ktwizard-state="current"] {
            background-color: #f4f6f9; }
            .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item[data-ktwizard-state="current"] .kt-wizard-v2__nav-icon {
              color: #333333; }
            .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item[data-ktwizard-state="current"]:after {
              left: 100%;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              content: " ";
              height: 0;
              width: 0;
              border: solid transparent;
              position: absolute;
              border-left-color: #f4f6f9;
              border-width: 1rem; }
        .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items.kt-wizard-v2__nav-items--clickable .kt-wizard-v2__nav-item {
          cursor: pointer; }
    @media (max-width: 768px) {
      .kt-wizard-v2 .kt-wizard-v2__aside {
        padding: 1rem;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%; }
        .kt-wizard-v2 .kt-wizard-v2__aside .kt-wizard-v2__nav .kt-wizard-v2__nav-items .kt-wizard-v2__nav-item[data-ktwizard-state="current"]:after {
          content: none; } }
  .kt-wizard-v2 .kt-wizard-v2__wrapper {
    background-color: #f4f6f9;
    width: 100%; }
    .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form {
      width: 80%;
      padding: 4rem 6rem 6rem; }
      @media (max-width: 1399px) {
        .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form {
          width: 100%; } }
      @media (max-width: 768px) {
        .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form {
          padding: 2rem 2.5rem 4rem; } }
      .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-wizard-v2__content {
        padding-bottom: 1.3rem;
        border-bottom: 1px solid #eeeef4;
        margin-bottom: 2rem; }
        .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-wizard-v2__content .kt-wizard-v2__review .kt-wizard-v2__review-item {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid #eeeef4; }
          .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-wizard-v2__content .kt-wizard-v2__review .kt-wizard-v2__review-item .kt-wizard-v2__review-title {
            font-weight: 600;
            font-size: 1.1rem;
            margin-bottom: 0.7rem; }
          .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-wizard-v2__content .kt-wizard-v2__review .kt-wizard-v2__review-item .kt-wizard-v2__review-content {
            line-height: 1.8rem; }
          .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-wizard-v2__content .kt-wizard-v2__review .kt-wizard-v2__review-item:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0; }
      .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        @media (max-width: 576px) {
          .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions {
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center; } }
        @media (max-width: 576px) {
          .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions .btn {
            margin: 0 0.5rem 1rem; } }
        .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
          margin-right: auto; }
          @media (max-width: 576px) {
            .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-prev"] {
              margin-right: 0.5rem; } }
        .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
          margin: auto 0 auto auto; }
          @media (max-width: 576px) {
            .kt-wizard-v2 .kt-wizard-v2__wrapper .kt-form .kt-form__actions [data-ktwizard-type="action-next"] {
              margin: 0 0 1rem; } }
  .kt-wizard-v2.kt-wizard-v2--white .kt-wizard-v2__wrapper {
    background-color: #ffffff; }
  .kt-wizard-v2 [data-ktwizard-type="step-info"] {
    display: none; }
    .kt-wizard-v2 [data-ktwizard-type="step-info"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v2 [data-ktwizard-type="step-content"] {
    display: none; }
    .kt-wizard-v2 [data-ktwizard-type="step-content"][data-ktwizard-state="current"] {
      display: block; }
  .kt-wizard-v2 [data-ktwizard-type="action-prev"] {
    display: none; }
  .kt-wizard-v2 [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v2 [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v2[data-ktwizard-state="first"] [data-ktwizard-type="action-prev"] {
    display: none; }
  .kt-wizard-v2[data-ktwizard-state="first"] [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v2[data-ktwizard-state="first"] [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v2[data-ktwizard-state="between"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v2[data-ktwizard-state="between"] [data-ktwizard-type="action-next"] {
    display: inline-block; }
  .kt-wizard-v2[data-ktwizard-state="between"] [data-ktwizard-type="action-submit"] {
    display: none; }
  .kt-wizard-v2[data-ktwizard-state="last"] [data-ktwizard-type="action-prev"] {
    display: inline-block; }
  .kt-wizard-v2[data-ktwizard-state="last"] [data-ktwizard-type="action-next"] {
    display: none; }
  .kt-wizard-v2[data-ktwizard-state="last"] [data-ktwizard-type="action-submit"] {
    display: inline-block; }

/* @include kt-media-above(xl) {
	.kt-wizard-v2 {

	}
}

// Above 1024px and below 1200px
@include kt-media-range(lg, xl) {
	.kt-wizard-v2 {

	}
}

// Below 1024px
@include kt-media-below(lg) {
	.kt-wizard-v2 {

	}
} */
