.custom-file {
  height: 52px;

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 52px;
    opacity: 0;
  }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    padding: 0 44px 0 14px;
    line-height: 52px;
    height: 52px;
    color: #495057;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    &::after {
      display: none;
    }
  }

  .custom-file-input:hover + .custom-file-label {
    border-color: rgba(0, 0, 0, 0.87);
  }

  .icon-upload {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 14px 10px;
    color: #c1c1c1;
  }
}

.btn-icon.h-52 {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-left: 0;
  height: 52px !important;
  width: 42px !important;
  border-radius: 0;
  &.delete {
    border-radius: 0 4px 4px 0;
  }
  .material-icons-outlined {
    font-size: 17px;
    color: #282a3c;
  }
  &:hover,
  &:active {
    .material-icons-outlined {
      color: #fff;
    }
  }
}
