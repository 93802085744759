/* starts, react-collapsible */

.react-collapsible {
  &.Collapsible{
    // Open
    &.open{
      .Collapsible__contentOuter{
        overflow: visible !important;
      }
    }
  }
}

/* end, react-color */
