.metronic-7-0-5 .label {
    padding: 0;
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 0.8rem;
    background-color: #EBEDF3;
    color: #3F4254;
    font-weight: 400;
    height: 20px;
    width: 20px;
    font-size: 0.8rem;
  }
  .metronic-7-0-5 .label.label-primary {
    color: #FFFFFF;
    background-color: #fa6160;
  }
  .metronic-7-0-5 .label.label-outline-primary {
    background-color: transparent;
    color: #fa6160;
    border: 1px solid #fa6160;
  }
  .metronic-7-0-5 .label.label-outline-primary.label-outline-2x {
    border: 2px solid #fa6160;
  }
  .metronic-7-0-5 .label.label-light-primary {
    color: #fa6160;
    background-color: #feefef;
  }
  .metronic-7-0-5 .label.label-secondary {
    color: #3F4254;
    background-color: #E4E6EF;
  }
  .metronic-7-0-5 .label.label-outline-secondary {
    background-color: transparent;
    color: #3F4254;
    border: 1px solid #E4E6EF;
  }
  .metronic-7-0-5 .label.label-outline-secondary.label-outline-2x {
    border: 2px solid #E4E6EF;
  }
  .metronic-7-0-5 .label.label-light-secondary {
    color: #E4E6EF;
    background-color: #EBEDF3;
  }
  .metronic-7-0-5 .label.label-success {
    color: #ffffff;
    background-color: #1BC5BD;
  }
  .metronic-7-0-5 .label.label-outline-success {
    background-color: transparent;
    color: #1BC5BD;
    border: 1px solid #1BC5BD;
  }
  .metronic-7-0-5 .label.label-outline-success.label-outline-2x {
    border: 2px solid #1BC5BD;
  }
  .metronic-7-0-5 .label.label-light-success {
    color: #1BC5BD;
    background-color: #C9F7F5;
  }
  .metronic-7-0-5 .label.label-info {
    color: #ffffff;
    background-color: #8950FC;
  }
  .metronic-7-0-5 .label.label-outline-info {
    background-color: transparent;
    color: #8950FC;
    border: 1px solid #8950FC;
  }
  .metronic-7-0-5 .label.label-outline-info.label-outline-2x {
    border: 2px solid #8950FC;
  }
  .metronic-7-0-5 .label.label-light-info {
    color: #8950FC;
    background-color: #EEE5FF;
  }
  .metronic-7-0-5 .label.label-warning {
    color: #ffffff;
    background-color: #FFA800;
  }
  .metronic-7-0-5 .label.label-outline-warning {
    background-color: transparent;
    color: #FFA800;
    border: 1px solid #FFA800;
  }
  .metronic-7-0-5 .label.label-outline-warning.label-outline-2x {
    border: 2px solid #FFA800;
  }
  .metronic-7-0-5 .label.label-light-warning {
    color: #FFA800;
    background-color: #FFF4DE;
  }
  .metronic-7-0-5 .label.label-danger {
    color: #ffffff;
    background-color: #F64E60;
  }
  .metronic-7-0-5 .label.label-outline-danger {
    background-color: transparent;
    color: #F64E60;
    border: 1px solid #F64E60;
  }
  .metronic-7-0-5 .label.label-outline-danger.label-outline-2x {
    border: 2px solid #F64E60;
  }
  .metronic-7-0-5 .label.label-light-danger {
    color: #F64E60;
    background-color: #FFE2E5;
  }
  .metronic-7-0-5 .label.label-light {
    color: #7E8299;
    background-color: #F3F6F9;
  }
  .metronic-7-0-5 .label.label-outline-light {
    background-color: transparent;
    color: #3F4254;
    border: 1px solid #F3F6F9;
  }
  .metronic-7-0-5 .label.label-outline-light.label-outline-2x {
    border: 2px solid #F3F6F9;
  }
  .metronic-7-0-5 .label.label-light-light {
    color: #F3F6F9;
    background-color: #F3F6F9;
  }
  .metronic-7-0-5 .label.label-dark {
    color: #ffffff;
    background-color: #181C32;
  }
  .metronic-7-0-5 .label.label-outline-dark {
    background-color: transparent;
    color: #181C32;
    border: 1px solid #181C32;
  }
  .metronic-7-0-5 .label.label-outline-dark.label-outline-2x {
    border: 2px solid #181C32;
  }
  .metronic-7-0-5 .label.label-light-dark {
    color: #181C32;
    background-color: #D1D3E0;
  }
  .metronic-7-0-5 .label.label-white {
    color: #3F4254;
    background-color: #ffffff;
  }
  .metronic-7-0-5 .label.label-outline-white {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  .metronic-7-0-5 .label.label-outline-white.label-outline-2x {
    border: 2px solid #ffffff;
  }
  .metronic-7-0-5 .label.label-light-white {
    color: #ffffff;
    background-color: #ffffff;
  }
  .metronic-7-0-5 .label.label-blue {
    color: #ffffff;
    background-color: #3699FF;
  }
  .metronic-7-0-5 .label.label-outline-blue {
    background-color: transparent;
    color: #3699FF;
    border: 1px solid #3699FF;
  }
  .metronic-7-0-5 .label.label-outline-blue.label-outline-2x {
    border: 2px solid #3699FF;
  }
  .metronic-7-0-5 .label.label-light-blue {
    color: #3699FF;
    background-color: #E1F0FF;
  }
  .metronic-7-0-5 .label.label-inline {
    width: auto;
    padding: 0.15rem 0.75rem;
    border-radius: 0.42rem;
  }
  .metronic-7-0-5 .label.label-inline.label-md {
    padding: 0.8rem 0.6rem;
  }
  .metronic-7-0-5 .label.label-inline.label-lg {
    padding: 0.9rem 0.75rem;
  }
  .metronic-7-0-5 .label.label-inline.label-xl {
    padding: 1rem 0.85rem;
  }
  .metronic-7-0-5 .label.label-pill {
    border-radius: 2rem;
  }
  .metronic-7-0-5 .label.label-rounded {
    border-radius: 0.42rem;
  }
  .metronic-7-0-5 .label.label-square {
    border-radius: 0;
  }
  .metronic-7-0-5 .label.label-dot {
    display: inline-block;
    font-size: 0 !important;
    vertical-align: middle;
    text-align: center;
  }
  .metronic-7-0-5 .label.label-inline {
    width: auto;
  }
  .metronic-7-0-5 .label.label-dot {
    line-height: 6px;
    min-height: 6px;
    min-width: 6px;
    height: 6px;
    width: 6px;
  }
  .metronic-7-0-5 .label.label-sm {
    height: 16px;
    width: 16px;
    font-size: 0.75rem;
  }
  .metronic-7-0-5 .label.label-sm.label-inline {
    width: auto;
  }
  .metronic-7-0-5 .label.label-sm.label-dot {
    line-height: 4px;
    min-height: 4px;
    min-width: 4px;
    height: 4px;
    width: 4px;
  }
  .metronic-7-0-5 .label.label-lg {
    height: 24px;
    width: 24px;
    font-size: 0.9rem;
  }
  .metronic-7-0-5 .label.label-lg.label-inline {
    width: auto;
  }
  .metronic-7-0-5 .label.label-lg.label-dot {
    line-height: 8px;
    min-height: 8px;
    min-width: 8px;
    height: 8px;
    width: 8px;
  }
  .metronic-7-0-5 .label.label-xl {
    height: 28px;
    width: 28px;
    font-size: 1rem;
  }
  .metronic-7-0-5 .label.label-xl.label-inline {
    width: auto;
  }
  .metronic-7-0-5 .label.label-xl.label-dot {
    line-height: 10px;
    min-height: 10px;
    min-width: 10px;
    height: 10px;
    width: 10px;
  }