.kanban-container {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: auto;
}

.kanban-container * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.kanban-container:after {
    clear: both;
    display: block;
    content: "";
}

.kanban-board {
    position: relative;
    float: left;
    background: #e2e4e6;
    /* -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1); */
}

.kanban-board.disabled-board {
    opacity: .3;
}

.kanban-board.is-moving.gu-mirror {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
}

.kanban-board.is-moving.gu-mirror .kanban-drag {
    overflow: hidden;
    padding-right: 50px;
}

.kanban-board header {
    font-size: 16px;
    padding: 15px;
}

.kanban-board header .kanban-title-board {
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: inline;
}

.kanban-board header .kanban-title-button {
    float: right;
}

.kanban-board .kanban-drag {
    min-height: 200px;
    padding: 20px;
}

.kanban-board:after {
    clear: both;
    display: block;
    content: "";
}

.kanban-item {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    /* -webkit-transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1); */
}

.kanban-item:hover {
    cursor: move;
}

.kanban-item:last-child {
    margin: 0;
}

.kanban-item.is-moving.gu-mirror {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
    height: auto !important;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2 !important;
    -webkit-transform: rotate(0) !important;
    transform: rotate(0) !important;
}

.drag_handler {
    background: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: relative;
    float: left;
    top: -3px;
    margin-right: 4px;
}

.drag_handler:hover {
    cursor: move;
}

.drag_handler_icon {
    position: relative;
    display: block;
    background: #000;
    width: 24px;
    height: 2px;
    top: 12px;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.drag_handler_icon:after, .drag_handler_icon:before {
    background: #000;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.drag_handler_icon:before {
    top: 6px;
}

.drag_handler_icon:after {
    bottom: 6px;
}

.kanban-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.kanban-container .kanban-board {
    float: none;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem !important;
    background-color: #F3F6F9;
    border-radius: 0.42rem;
}

.kanban-container .kanban-board:last-child {
    margin-right: 0 !important;
}

.kanban-container .kanban-board .kanban-board-header {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}

.kanban-container .kanban-board .kanban-board-header .kanban-title-board {
    font-size: 1.2rem;
    font-weight: 500;
    color: #181C32;
}

.kanban-container .kanban-board .kanban-board-header.primary {
    background-color: #fa6160;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.primary .kanban-title-board {
    color: #FFFFFF;
}

.kanban-container .kanban-board .kanban-board-header.light-primary {
    background-color: rgba(250, 97, 96, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-primary .kanban-title-board {
    color: #fa6160;
}

.kanban-container .kanban-board .kanban-board-header.secondary {
    background-color: #E4E6EF;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.secondary .kanban-title-board {
    color: #3F4254;
}

.kanban-container .kanban-board .kanban-board-header.light-secondary {
    background-color: rgba(228, 230, 239, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-secondary .kanban-title-board {
    color: #E4E6EF;
}

.kanban-container .kanban-board .kanban-board-header.success {
    background-color: #1BC5BD;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.success .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-success {
    background-color: rgba(27, 197, 189, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-success .kanban-title-board {
    color: #1BC5BD;
}

.kanban-container .kanban-board .kanban-board-header.info {
    background-color: #8950FC;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.info .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-info {
    background-color: rgba(137, 80, 252, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-info .kanban-title-board {
    color: #8950FC;
}

.kanban-container .kanban-board .kanban-board-header.warning {
    background-color: #FFA800;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.warning .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-warning {
    background-color: rgba(255, 168, 0, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-warning .kanban-title-board {
    color: #FFA800;
}

.kanban-container .kanban-board .kanban-board-header.danger {
    background-color: #F64E60;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.danger .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-danger {
    background-color: rgba(246, 78, 96, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-danger .kanban-title-board {
    color: #F64E60;
}

.kanban-container .kanban-board .kanban-board-header.light {
    background-color: #F3F6F9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light .kanban-title-board {
    color: #7E8299;
}

.kanban-container .kanban-board .kanban-board-header.light-light {
    background-color: rgba(243, 246, 249, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-light .kanban-title-board {
    color: #F3F6F9;
}

.kanban-container .kanban-board .kanban-board-header.dark {
    background-color: #181C32;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.dark .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-dark {
    background-color: rgba(24, 28, 50, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-dark .kanban-title-board {
    color: #181C32;
}

.kanban-container .kanban-board .kanban-board-header.white {
    background-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.white .kanban-title-board {
    color: #3F4254;
}

.kanban-container .kanban-board .kanban-board-header.light-white {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-white .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.blue {
    background-color: #3699FF;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.blue .kanban-title-board {
    color: #ffffff;
}

.kanban-container .kanban-board .kanban-board-header.light-blue {
    background-color: rgba(54, 153, 255, 0.1);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-board-header.light-blue .kanban-title-board {
    color: #3699FF;
}

.kanban-container .kanban-board .kanban-drag .kanban-item {
    border-radius: 0.42rem;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="primary"] {
    background-color: #fa6160;
    color: #FFFFFF;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-primary"] {
    background-color: rgba(250, 97, 96, 0.1);
    color: #fa6160;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="secondary"] {
    background-color: #E4E6EF;
    color: #3F4254;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-secondary"] {
    background-color: rgba(228, 230, 239, 0.1);
    color: #E4E6EF;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="success"] {
    background-color: #1BC5BD;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-success"] {
    background-color: rgba(27, 197, 189, 0.1);
    color: #1BC5BD;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="info"] {
    background-color: #8950FC;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-info"] {
    background-color: rgba(137, 80, 252, 0.1);
    color: #8950FC;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="warning"] {
    background-color: #FFA800;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-warning"] {
    background-color: rgba(255, 168, 0, 0.1);
    color: #FFA800;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="danger"] {
    background-color: #F64E60;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-danger"] {
    background-color: rgba(246, 78, 96, 0.1);
    color: #F64E60;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light"] {
    background-color: #F3F6F9;
    color: #7E8299;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-light"] {
    background-color: rgba(243, 246, 249, 0.1);
    color: #F3F6F9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="dark"] {
    background-color: #181C32;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-dark"] {
    background-color: rgba(24, 28, 50, 0.1);
    color: #181C32;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="white"] {
    background-color: #ffffff;
    color: #3F4254;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-white"] {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="blue"] {
    background-color: #3699FF;
    color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.kanban-container .kanban-board .kanban-drag .kanban-item[data-class="light-blue"] {
    background-color: rgba(54, 153, 255, 0.1);
    color: #3699FF;
    -webkit-box-shadow: none;
    box-shadow: none;
}