//
// Modal
//




// Base
.modal {
    .modal-content {
        .modal-footer{
            .btn{
                min-width: 100px;
            }
        }
    }
}