// common
@import './common/common';
@import './common/breakpoints';

@import url(./npm-packages/react-bootstrap/bootstrap.scss);
@import url(./npm-packages/react-color/react-color.scss);
@import url(./npm-packages/react-collapsible/react-collapsible.scss);

@import url(./custom/_custom.scss);

.fw-500{
    font-weight: 500;
}
