// variables
$conversations-app-side-item-hover : rgba(77, 89, 149, 0.06);
$conversations-app-side-height : calc(100vh - 223px);
$conversations-app-content-height : calc(100vh - 195px);

// mixins
@mixin conversations-css-scroll {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        opacity: 1;
    }
    &::-webkit-scrollbar-thumb {
        background: #e0e2f0;
        border-radius: 5px;
        opacity: 1;
    }
    &::-webkit-scrollbar-thumb:hover > {
        background: #e0e2f0;
        opacity: 1;
    }
}

.c-chats {
    // left column
    .kt-app__aside {
        .kt-widget {
            height: $conversations-app-side-height;
            &.kt-widget--users {
                // items
                .kt-widget__items {
                    height: $conversations-app-side-height;
                    overflow-y: scroll;
                    padding: 0 6px 0 0;
                    @include conversations-css-scroll;
                    .kt-widget__item {
                        margin: 2px 0;
                        padding: 12px;
                        border-radius: 6px;
                        cursor: pointer;
                        align-items: center;
                        &.selected,
                        &:hover {
                            background: $conversations-app-side-item-hover;
                        }
                        .kt-widget__info {
                            margin-left: 0;
                            margin-right: 4px;
                            padding-top: 0;
                            .kt-widget__desc {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                word-break: break-all;
                                @supports (-webkit-line-clamp: 2) {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: initial;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                        .kt-widget__action{}
                        .kt-widget__username {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 240px;
                        }
                        .kt-widget__action {
                            padding-top: 0;
                        }
                    }
                }
                // no items
                .no-content{
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    z-index: 0;
                    left: 4px;
                }
            }
        }
    }
    // right column
    .kt-app__content {
        .kt-portlet__body {
            padding: 0 0 0 0;
            .kt-chat__messages {
                height: $conversations-app-content-height;
                .gc-container {
                    .gc-outer-container-messages {
                        padding: 0 25px 0 25px;
                        height: 100% !important;
                        overflow: hidden;
                        .gc-container-messages {
                            .gc-container-list {
                                overflow-x: hidden !important;
                                @include conversations-css-scroll;
                            }
                        }
                    }
                }
                // Footer
                .kt-portlet__foot{
                    .kt-chat__input {
                    .kt-chat__editor{
                        textarea{
                            border: 1px solid #ebedf2 !important;
                            padding: 8px 12px;
                        }
                    }
                }
                }
            }
            .card-body-scroll-lock {
                height: $conversations-app-content-height;
                overflow-y: scroll;
                //@include conversations-css-scroll;
            }
        }
    }
}
