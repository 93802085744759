/* Custom Scripts Below */
.visible-hidden {
  visibility: hidden;
}
/* Margin & Padding */
.side-margin-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.kt-aside--minimize .side-margin-20 {
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__heading
  .kt-menu__ver-arrow,
.kt-aside-menu
  .kt-menu__nav
  > .kt-menu__item
  > .kt-menu__link
  .kt-menu__ver-arrow {
  font-size: 1.05rem;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-50 {
  margin-right: 50px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-t-0 {
  padding-top: 0px;
}

/* Stat Boxes */
.kt-widget24 {
  text-align: center;
  margin: 30px 0px;
}
.kt-widget24__info {
  width: 100%;
}
.kt-widget24__title {
  display: block !important;
}
.kt-widget24 .kt-widget24__details .kt-widget24__stats {
  font-size: 40px;
}

/* Add Page Card */
.add-page-portlet {
  box-shadow: none;
  border: 10px dashed #ddd;
  background: none;
}
.add-page-portlet a.page-name {
  color: #bbb !important;
}
.add-page-two {
  box-shadow: none;
  border: none;
  background: #d8dbe8;
}
.add-page-two a.page-name {
  color: #fff !important;
}
@media (min-width: 1200px) {
  .add-page-portlet .kt-widget__head,
  .add-page-two .kt-widget__head {
    margin-top: 50px !important;
  }
}

/* Page Card */
.kt-widget__stats {
  padding: 1.7rem 1.5rem 0;
  font-size: 13px;
}
.kt-widget__stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.kt-widget__stat .kt-widget__label {
  color: #48465b;
  font-weight: 600;
}
.kt-widget__stat .kt-widget__data {
  color: #74788d;
  font-weight: 400;
}

/* Inputs */
.keywords-lg {
  padding: 0.65rem 1.65rem;
  font-size: 1.25rem;
}
.kt-widget.kt-widget--user-profile-4
  .kt-widget__head
  .kt-widget__content
  .kt-widget__section
  .page-name {
  font-size: 1.9rem;
}
.kt-badge.kt-badge--warning {
  color: #fff;
}

.kt-widget17 .kt-widget17__stats {
  margin: 0;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-wrapper
  .kt-header__topbar-icon {
  background-color: #f3f6f9;
  font-size: 1.5rem;
  font-weight: 500;
}
.kt-header .kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon i,
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover
  .kt-header__topbar-icon
  i {
  color: #333;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-wrapper
  .kt-header__topbar-icon:hover {
  background-color: #333;
}
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item
  .kt-header__topbar-icon:hover
  i,
.kt-header
  .kt-header__topbar
  .kt-header__topbar-item:hover
  .kt-header__topbar-icon:hover
  i {
  color: #fff;
}
.kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--quickpanel
  .kt-header__topbar-icon {
  background: #fbce44;
  margin-right: 13px;
}
.kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--quickpanel {
  margin-left: 0.5rem;
}
.note-input {
  padding-bottom: 2rem;
  border-bottom: 1px solid #eeeef4;
}
.table-status {
  border: 1px solid #e2e5ec !important;
  height: 38px !important;
}

/* Start TABLE Styles */
.ReactTable {
  border: none;
}

.Table__pagination .switchPageButton:hover,
.Table__pageButton:hover,
.Table__pageButton--active {
  background: #333333;
}

.rt-td {
  color: #6c7293;
  padding: 0;
}

.rt-tr-group:hover {
  background-color: #fafbfc;
}

.-sort-desc,
.-sort-asc {
  box-shadow: none !important;
}

.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::before {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.rt-tr div:not(.-sort-asc):not(.-sort-desc) .rt-resizer::after {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}

.-sort-asc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
}
.-sort-asc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  opacity: 0.3;
}
.-sort-desc .rt-resizer::before {
  content: "\2191";
  color: #595d6e;
  vertical-align: "center";
  opacity: 0.3;
}
.-sort-desc .rt-resizer::after {
  content: "\2193";
  color: #595d6e;
  vertical-align: "center";
}

.ReactTable .rt-resizer {
  top: 5px;
}

.ReactTable .rt-td {
  align-self: center;
}

.Table__page .select-page:hover {
  background-color: #333333;
}

.rt-resizable-header-content {
  color: #595d6e !important;
  font-weight: 500;
}

.ReactTable .rt-expander:after {
  border-top: 7px solid #3699ff !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: 0 !important;
}

.ReactTable .rt-thead.-header {
  padding-bottom: 0 !important;
}

.ReactTable .rt-tr {
  padding-top: unset !important;
  padding-bottom: unset !important;
  overflow-x: auto;
}

.ReactTable .rt-tbody .rt-td {
  border-right: unset !important;
}

.ReactTable .switchPageButton,
.ReactTable .Table__pageButton {
  border-radius: 50% !important;
  font-size: unset !important;
}
/* End TABLE Styles */

.rdw-editor-main {
  padding: 1.15rem 1.65rem;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-color: #e2e5ec;
  border-style: solid;
}

.text-editor-wrapper {
  border: 1px solid #e2e5ec;
}

.rdw-editor-toolbar {
  border-style: none;
}

.atricle-modal .modal-dialog {
  /* width: 50% !important;
  max-width: unset; */
}

.modal-50vw {
  width: 50vw;
  max-width: 50vw;
}

.modal-60vw {
  width: 60vw;
  max-width: 60vw;
}

.modal-70vw {
  width: 70vw;
  max-width: 70vw;
}

.meterial-icon-picker {
  background-color: #ebeeff;
}

.meterial-icon-picker.invert {
  background-color: transparent;
}

.material-icons-icon {
  color: #333333 !important;
}

.material-icons-icon.invert {
  color: white !important;
}

.meterial-icon-picker:hover {
  background-color: #333333;
}

.meterial-icon-picker.invert:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.input-group-prepend:hover .material-icons-icon {
  color: white !important;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #f8f8f9;
  border: 1px solid #e2e5ec;
  border-radius: 4px;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

span.input-group-addon {
  display: inline-flex;
  align-items: center;
}

.input-group-addon:first-child {
  border-right: 0;
}

.form-control.form-control-solid {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #3f4254;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

.input-group.input-group-solid,
.async-select-paginate__control {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f6f9 !important;
  border-radius: 0.42rem;
}

.async-select-paginate-filter__control {
  border-color: #e2e5ec !important;
}

.async-select-paginate:first-child,
.async-select-paginate > div:first-child {
  background-color: #f3f6f9 !important;
}

.input-group.input-group-solid .input-group-prepend,
.input-group.input-group-solid .input-group-append,
.input-group.input-group-solid .input-group-addon,
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.input-group-append-bg-dark span {
  background: #333;
  min-width: 100px;
  border: 0;
  color: white;
}

.input-group-append-bg-dark-error span {
  background: #fa6160;
  color: white;
}

.input-group-append-bg-green span {
  background: #37db70;
  color: white;
}

.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}

.chip-container > div,
.chip-container input {
  background-color: #f3f6f9 !important;
  border: none !important;
}

.async-select-paginate div {
  border: 0;
}

.separator.separator-custom {
  border-bottom-color: #f3f6f9;
}
.separator.separator-border-2 {
  border-bottom-width: 2px;
}
.separator.separator-dashed {
  border-bottom: 1px dashed #ebedf3;
}
.separator {
  height: 0;
  border-bottom: solid;
}

.react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: transparent;
}

.async-select-paginate__control {
  background-color: red;
}

.w-50 {
  width: 50vw;
  max-width: 50vw;
}

.form-control:disabled {
  background-color: #f1f1f1;
  opacity: 1;
}

.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-indeterminate {
  background-color: #c1c1c1 !important;
}
.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-barColorPrimary,
.MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
  background-color: #333 !important;
}

.btn.btn-light {
  color: #80808f;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-hover-primary:focus:not(.btn-text),
.btn.btn-hover-primary.focus:not(.btn-text) {
  color: #ffffff !important;
  background-color: #3f494d !important;
  border-color: #3f494d !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.btn.btn-hover-primary:focus:not(.btn-text) i,
.btn.btn-hover-primary.focus:not(.btn-text) i {
  color: #ffffff !important;
}

.disable-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Poppins, Helvetica, sans-serif;
  text-align: center;
  font-size: 28px;
  color: #333;
  font-weight: 600;
}

.disable-view .logo-svg {
  width: 240px;
  height: 240px;
  top: 31px;
  position: relative;
  overflow: hidden;
}

.modal-70vw {
  width: 70vw;
  max-width: 70vw;
  margin: 1.75rem auto;
}
@media (max-width: 576px) {
  .modal-70vw {
    width: 90vw;
    max-width: 90vw;
    margin: 1.75rem auto;
  }
}

.choose-image {
  position: relative;
  z-index: 0;
}
.choose-image::after {
  background-image: url(../media/bg/step-02.png);
  content: " ";
  z-index: 1;
}
.choose-image .bg-text {
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.choose-image::after,
.choose-image .uploaded-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.choose-image .uploaded-image {
  z-index: 3;
}
.choose-image .uploaded-image.type-illustration {
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose-image .uploaded-image.type-illustration svg {
  max-height: 80%;
  width: auto;
}
.choose-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}

/* starts, color-picker */
.color-picker .color {
  position: relative;
  z-index: 0;
  display: inline-block;
  margin-top: 6px;
}
.color-picker .color-box-value {
  margin-top: 6px;
}
.color-picker .color div.selected::before {
  position: absolute;
  top: 13.5px;
  left: 13px;
  color: white;
  font-size: 1.5em;
}
/* starts, color-picker */

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.c-separator hr {
  border-top: 3px dashed rgba(0, 0, 0, 0.1);
}

.ReactTable .rt-expander:after {
  border-top: 7px solid #333333 !important;
}

.switch {
  display: inline-block;
  font-size: 1rem;
}
.switch input:empty {
  margin-left: -999px;
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  opacity: 0;
}
.switch input:empty ~ span {
  display: inline-block;
  position: relative;
  float: left;
  width: 1px;
  text-indent: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  content: " ";
  -webkit-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
}
.switch input[disabled] {
  cursor: not-allowed;
}
.switch input[disabled] ~ span:after,
.switch input[disabled] ~ span:before {
  cursor: not-allowed;
  opacity: 0.5;
}
.switch.switch-icon input:checked ~ span:after {
  font-family: Ki;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  content: "";
  line-height: 0;
}

.switch input:empty ~ span {
  margin: 2px 0;
  height: 30px;
  width: 57px;
  border-radius: 15px;
}

.switch input:empty ~ span:before,
.switch input:empty ~ span:after {
  width: 54px;
  border-radius: 15px;
}

.switch input:empty ~ span:after {
  height: 24px;
  width: 24px;
  top: 3px;
  bottom: 3px;
  margin-left: 3px;
  font-size: 0.65em;
  text-align: center;
  vertical-align: middle;
}

.switch input:checked ~ span:after {
  margin-left: 26px;
}

.switch.switch-sm input:empty ~ span {
  margin: 2px 0;
  height: 24px;
  width: 40px;
  border-radius: 12px;
}

.switch.switch-sm input:empty ~ span:before,
.switch.switch-sm input:empty ~ span:after {
  width: 38px;
  border-radius: 12px;
}

.switch.switch-sm input:empty ~ span:after {
  height: 20px;
  width: 20px;
  top: 2px;
  bottom: 2px;
  margin-left: 2px;
  font-size: 0.55em;
  text-align: center;
  vertical-align: middle;
}

.switch.switch-sm input:checked ~ span:after {
  margin-left: 16px;
}

.switch.switch-lg input:empty ~ span {
  margin: 2px 0;
  height: 40px;
  width: 75px;
  border-radius: 20px;
}

.switch.switch-lg input:empty ~ span:before,
.switch.switch-lg input:empty ~ span:after {
  width: 72px;
  border-radius: 20px;
}

.switch.switch-lg input:empty ~ span:after {
  height: 34px;
  width: 34px;
  top: 3px;
  bottom: 3px;
  margin-left: 3px;
  font-size: 0.75em;
  text-align: center;
  vertical-align: middle;
}

.switch.switch-lg input:checked ~ span:after {
  margin-left: 34px;
}

.form-group.row .switch {
  margin-top: 0.15rem;
}
.form-group.row .switch.switch-sm {
  margin-top: 0.3rem;
}
.form-group.row .switch.switch-lg {
  margin-top: 0rem;
  position: relative;
  top: -0.3rem;
}

.switch input:empty ~ span:before {
  background-color: #ecf0f3;
}

.switch input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch input:checked ~ span:before {
  background-color: #ecf0f3;
}

.switch input:checked ~ span:after {
  opacity: 1;
  color: #ffffff;
  background-color: #3699ff;
}

.switch.switch-primary:not(.switch-outline) input:empty ~ span:before {
  background-color: #3699ff;
}

.switch.switch-primary:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-primary:not(.switch-outline) input:checked ~ span:before {
  background-color: #3699ff;
}

.switch.switch-primary:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #3699ff;
  background-color: #ffffff;
}

.switch.switch-outline.switch-primary input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-primary input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-primary input:checked ~ span:before {
  border: 2px solid #3699ff;
  background-color: transparent;
}

.switch.switch-outline.switch-primary input:checked ~ span:after {
  color: #ffffff;
  background-color: #3699ff;
}

.switch.switch-secondary:not(.switch-outline) input:empty ~ span:before {
  background-color: #e5eaee;
}

.switch.switch-secondary:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-secondary:not(.switch-outline) input:checked ~ span:before {
  background-color: #e5eaee;
}

.switch.switch-secondary:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #e5eaee;
  background-color: #ffffff;
}

.switch.switch-outline.switch-secondary input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-secondary input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-secondary input:checked ~ span:before {
  border: 2px solid #e5eaee;
  background-color: transparent;
}

.switch.switch-outline.switch-secondary input:checked ~ span:after {
  color: #ffffff;
  background-color: #e5eaee;
}

.switch.switch-success:not(.switch-outline) input:empty ~ span:before {
  background-color: #1bc5bd;
}

.switch.switch-success:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-success:not(.switch-outline) input:checked ~ span:before {
  background-color: #1bc5bd;
}

.switch.switch-success:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #1bc5bd;
  background-color: #ffffff;
}

.switch.switch-outline.switch-success input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-success input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-success input:checked ~ span:before {
  border: 2px solid #1bc5bd;
  background-color: transparent;
}

.switch.switch-outline.switch-success input:checked ~ span:after {
  color: #ffffff;
  background-color: #1bc5bd;
}

.switch.switch-info:not(.switch-outline) input:empty ~ span:before {
  background-color: #8950fc;
}

.switch.switch-info:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-info:not(.switch-outline) input:checked ~ span:before {
  background-color: #8950fc;
}

.switch.switch-info:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #8950fc;
  background-color: #ffffff;
}

.switch.switch-outline.switch-info input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-info input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-info input:checked ~ span:before {
  border: 2px solid #8950fc;
  background-color: transparent;
}

.switch.switch-outline.switch-info input:checked ~ span:after {
  color: #ffffff;
  background-color: #8950fc;
}

.switch.switch-warning:not(.switch-outline) input:empty ~ span:before {
  background-color: #ffa800;
}

.switch.switch-warning:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-warning:not(.switch-outline) input:checked ~ span:before {
  background-color: #ffa800;
}

.switch.switch-warning:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #ffa800;
  background-color: #ffffff;
}

.switch.switch-outline.switch-warning input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-warning input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-warning input:checked ~ span:before {
  border: 2px solid #ffa800;
  background-color: transparent;
}

.switch.switch-outline.switch-warning input:checked ~ span:after {
  color: #ffffff;
  background-color: #ffa800;
}

.switch.switch-danger:not(.switch-outline) input:empty ~ span:before {
  background-color: #f64e60;
}

.switch.switch-danger:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-danger:not(.switch-outline) input:checked ~ span:before {
  background-color: #f64e60;
}

.switch.switch-danger:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #f64e60;
  background-color: #ffffff;
}

.switch.switch-outline.switch-danger input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-danger input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-danger input:checked ~ span:before {
  border: 2px solid #f64e60;
  background-color: transparent;
}

.switch.switch-outline.switch-danger input:checked ~ span:after {
  color: #ffffff;
  background-color: #f64e60;
}

.switch.switch-light:not(.switch-outline) input:empty ~ span:before {
  background-color: #f3f6f9;
}

.switch.switch-light:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-light:not(.switch-outline) input:checked ~ span:before {
  background-color: #f3f6f9;
}

.switch.switch-light:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #f3f6f9;
  background-color: #ffffff;
}

.switch.switch-outline.switch-light input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-light input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-light input:checked ~ span:before {
  border: 2px solid #f3f6f9;
  background-color: transparent;
}

.switch.switch-outline.switch-light input:checked ~ span:after {
  color: #ffffff;
  background-color: #f3f6f9;
}

.switch.switch-dark:not(.switch-outline) input:empty ~ span:before {
  background-color: #212121;
}

.switch.switch-dark:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-dark:not(.switch-outline) input:checked ~ span:before {
  background-color: #212121;
}

.switch.switch-dark:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #212121;
  background-color: #ffffff;
}

.switch.switch-outline.switch-dark input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-dark input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-dark input:checked ~ span:before {
  border: 2px solid #212121;
  background-color: transparent;
}

.switch.switch-outline.switch-dark input:checked ~ span:after {
  color: #ffffff;
  background-color: #212121;
}

.switch.switch-white:not(.switch-outline) input:empty ~ span:before {
  background-color: #ffffff;
}

.switch.switch-white:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-white:not(.switch-outline) input:checked ~ span:before {
  background-color: #ffffff;
}

.switch.switch-white:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #ffffff;
  background-color: #ffffff;
}

.switch.switch-outline.switch-white input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-white input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-white input:checked ~ span:before {
  border: 2px solid #ffffff;
  background-color: transparent;
}

.switch.switch-outline.switch-white input:checked ~ span:after {
  color: #ffffff;
  background-color: #ffffff;
}

.switch.switch-dark-75:not(.switch-outline) input:empty ~ span:before {
  background-color: #464e5f;
}

.switch.switch-dark-75:not(.switch-outline) input:empty ~ span:after {
  background-color: #ffffff;
  opacity: 0.7;
}

.switch.switch-dark-75:not(.switch-outline) input:checked ~ span:before {
  background-color: #464e5f;
}

.switch.switch-dark-75:not(.switch-outline) input:checked ~ span:after {
  opacity: 1;
  color: #464e5f;
  background-color: #ffffff;
}

.switch.switch-outline.switch-dark-75 input:empty ~ span:before {
  border: 2px solid #ecf0f3;
  background-color: transparent;
}

.switch.switch-outline.switch-dark-75 input:empty ~ span:after {
  background-color: #ecf0f3;
}

.switch.switch-outline.switch-dark-75 input:checked ~ span:before {
  border: 2px solid #464e5f;
  background-color: transparent;
}

.switch.switch-outline.switch-dark-75 input:checked ~ span:after {
  color: #ffffff;
  background-color: #464e5f;
}

/* starts image picker modal */
.image-picker-modal {
  width: 70% !important;
}
.images-search-icon {
  position: absolute;
  top: 0;
  right: 15px;
  cursor: pointer;
  background-color: #e6e6e6;
  padding: 10px;
  padding-bottom: 5px;
}
.images-search-icon:hover {
  background-color: #f3f3f3;
}
.image-search-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 4px;
}
.image-search-thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 4px;
  font-weight: 200;
  font-size: 12px;
}
.type-illustration .tile-img img {
  border: 1px solid #efefef;
}
/* end image picker modal */

/* starts, domain settings */
ul.nameservers {
  list-style: none;
  padding: 0;
  margin-top: 16px;
}
ul.nameservers li {
  padding: 1px 0;
}
.nameserver {
  margin-bottom: 16px;
}
.nameserver .name {
  min-width: 200px;
}
.nameserver .btn-outline-dark:not(:hover) {
  color: #333333;
}
.nameserver.b-color {
  border-color: #adadad;
}
ul.nameservers,
.nameserver {
  border: 1px solid #ebedf2;
  padding: 12px;
  border-radius: 4px;
  background: #f2f3f8;
  color: #111;
  font-weight: 400;
  font-size: 14px;
}
/* end, domain settings */

/* starts, use existing domain */
.use-existing-domain .rounded {
  border-radius: 6px !important;
}
.use-existing-domain .shadow {
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
}
.use-existing-domain .pricing-rates {
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #adb5bd !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 100%;
}
.use-existing-domain .pricing-rates.business-rate:hover,
.use-existing-domain .pricing-rates.business-rate.active {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  z-index: 2;
  background: #333;
  border-color: #333333 !important;
  -webkit-box-shadow: 0 5px 13px rgba(51, 51, 51, 0.2) !important;
  box-shadow: 0 5px 13px rgba(51, 51, 51, 0.2) !important;
}
.use-existing-domain .pricing-rates.business-rate:hover > *,
.use-existing-domain .pricing-rates.business-rate.active > * {
  color: #fff;
}
.use-existing-domain .pricing-rates.starter-plan {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  z-index: 2;
  border-color: #333333 !important;
}
@media (max-width: 767px) {
  .use-existing-domain .pricing-rates.starter-plan {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.use-existing-domain .pricing-rates.best-plan:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
.use-existing-domain .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.use-existing-domain .card .card-body {
  padding: 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
}
.use-existing-domain .list-unstyled li {
  position: relative;
  z-index: 0;
  padding: 0 0 0 23px;
}
/* end, use existing domain */

/* starts, alert */
.alert.alert-custom.alert-warning .alert-icon i {
  opacity: 0.8;
  color: #ffffff;
}
.alert.alert-custom.alert-warning .alert-text {
  color: #ffffff;
}

.alert-button button {
  margin-top: 8px;
  background: transparent;
  color: white;
  border: 1px solid white;
  padding: 6px 20px;
}

.alert-button button:hover {
  background-color: white;
  color: #ffb822;
}
/* end, alert */

/* Start, Kanban custom css */
.kanban-container {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.kanban-container .kanban-board {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.kanban-container .kanban-board span.bg-transparent {
  padding: 0;
  height: auto;
}

.kanban-v2 {
}

.kanban-v2 .kanban-container {
  overflow-y: auto;
}

.kanban-v2 .kanban-container {
  min-height: calc(100vh - 166px);
  padding: 0 0 0 25px;
}
.mobile-crm .kanban-v2 .kanban-container {
  min-height: calc(100vh - 40px);
}

.kanban-v2 .kanban-container .kanban-board {
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 250px !important;
}
.kanban-v2 .kanban-board header {
  padding-top: 11px;
  padding-bottom: 11px;
}
.kanban-v2 .kanban-board-header .btn {
  border-radius: 0.42rem;
}
.kanban-v2 .kanban-container .kanban-board .kanban-title-board .label {
  position: relative;
  z-index: 0;
  top: -2.5px;
  line-height: normal;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon {
  position: relative;
  z-index: 0;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon .bg-color {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 0.42rem;
  display: none;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon:not(:hover) .default {
  display: block;
}
.kanban-v2 .kanban-container .kanban-board .btn-icon:hover .hover {
  display: block;
}
.kanban-v2 .kanban-container .kanban-board .kanban-drag {
  min-height: auto;
  padding: 10px 0 10px 10px;
  margin: 10px;
}
.kanban-v2 .kanban-container .kanban-board .kanban-drag .kanban-drag-list {
  overflow-y: auto;
  max-height: calc(100vh - 270px);
  padding: 0 10px 0 0;
}
.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb {
  background: #e4e6ef;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb:hover {
  background: #e4e6ef;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-thumb:active {
  background: #e4e6ef;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track:hover {
  background: transparent;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-track:active {
  background: transparent;
}

.kanban-v2
  .kanban-container
  .kanban-board
  .kanban-drag
  .kanban-drag-list::-webkit-scrollbar-corner {
  background: transparent;
}

.kanban-v2 .kanban-container::-webkit-scrollbar {
  height: 10px;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb {
  background: #d1d3e0;
  border: 0px none #ffffff;
  border-radius: 0.42rem !important;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb:hover {
  background-color: #d1d3e0;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-thumb:active {
  background-color: #d1d3e0;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0.42rem !important;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track:hover {
  background: transparent;
  background-color: #f3f6f9;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-track:active {
  background: transparent;
  background-color: #f3f6f9;
}

.kanban-v2 .kanban-container::-webkit-scrollbar-corner {
  background: transparent;
}
@media (max-width: 768px) {
  .kanban-container .kanban-board {
    margin-right: 1.25rem !important;
  }
}
.inline-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.kanban-list-idle {
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
}
/* end, Kanban custom css */

/* starts, Kanban and Table tabs css */
.c-tabs .nav-pills {
  display: inline-flex;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 60px !important;
  margin-bottom: 26px;
  padding: 6px;
  font-weight: 600;
}
@media (min-width: 1025px) {
  .c-tabs .nav-pills {
    position: fixed;
    background-color: #eef0f8;
    top: 11px;
    z-index: 100;
    left: 50%;
  }
}
.c-tabs .nav-pills .nav-link.active,
.c-tabs .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #333333;
  border-radius: 48px !important;
}
.c-tabs .nav-link {
  padding: 6.5px 30px;
}
.c-tabs .nav-link:hover {
  color: #333333;
}
/* end, Kanban and Table tabs css */

/*** Chats ***/

.sender-info {
  color: #48465b;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.message-date {
  color: #74788d;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 2.95px;
  font-family: Poppins, Helvetica, sans-serif;
}

.justify-text-end {
  justify-content: end;
}

.justify-text-start {
  justify-content: start;
}

.chat-link-content {
  color: #48465b;
}

.chat-link-content:hover {
  color: #74788d;
}

.kt-chat__icon .profile-icon {
  height: 20px;
}

.kt-message__input {
  width: 100%;
  padding: 6px;
  border: none;
  outline: none;
  border-radius: 0.25rem;
}

.kt-message__input::placeholder {
  color: #74788d;
}

.kt-editor__width {
  width: 80%;
}

.send-icon {
  border: none;
  outline: none;
  background: none;
}

.send-icon > img {
  width: 25px;
  margin-top: 0.3rem;
}

.chat-input {
  background-color: #f5f5f5;
}

.kt-chat .kt-chat__input .kt-chat__editor textarea {
  background-color: #ffffff !important;
}

.bg-whitesmoke {
  background: whitesmoke;
}

/*** Shimmer Effect ***/
.shine {
  background: #f6f7f8;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 800px;
  display: block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
}

.shine-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

/*** Personal Web Designer ***/
.notification-message-container {
  background: #333 !important;
}

.notification-message {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.notification-message-head span {
  color: white;
}

.notification-message-body span {
  color: #e5e5e5;
}

/* start add ons */
.add-on-item {
  color: #595d6e;
  background: rgb(249, 249, 252);
  align-items: center;
  justify-content: center;
  padding: 30px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.add-on-item {
  border-style: solid;
  border-color: #7b7d86
}

.add-on-item-disabled {
  opacity: 0.4;
}

.add-on-item-header {
}

.add-on-item-title {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}
.add-on-item-subtitle {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.add-on-item-body {
  font-size: 10px;
  text-align: center;
}

.add-on-item-controls {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:  0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.add-on-item-controls:hover {
  opacity: 1;
}

.add-on-item-included {
  color: white;
  background: #333;
  position: absolute;
  top: 0px;
  right: -0px;;
  padding: 0px 15px 0px 15px;
  font-size: 10px;
  z-index: 20;
  border-bottom-left-radius: 15px
}
/* end add ons */

/* starts, login-1 page fixed login-aside */
.login-1 {
}
@media (min-width: 992px) {
  .login-1 .login-aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1025px) {
  .login-1 {
    padding-left: 600px;
  }
}
@media (max-width: 1399.98px) and (min-width: 992px) {
  .login-1 {
    padding-left: 450px;
  }
}
.login.login-1 .login-content.login-content-loading {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.login.login-1 .login-content.login-content-loading .login-form {
  max-height: 100vh;
}
/* end, login-1 page fixed login-aside */
