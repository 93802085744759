// Shop

.page {
    &.package {

        // choose-image
        .choose-image {
            width: 190px;
            max-width: 100%;
            height: 280px;
            // border: 3px solid #fff;
            // box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);

            .bg-text {
                font-weight: normal;
                font-size: 12px;
            }
        }

        // preview
        .preview {
            height: 560px;
            margin: 7px 0 0 0px;

            box-shadow: 0 0 1.5rem 0.5rem rgb(0 0 0 / 16%);

            //details
            .details {
                padding: 44px;
                text-align: center;
                position: relative;
                z-index: 0;
                width: 243px;

                // @include media-breakpoint-xxl {
                //     width: 270px;
                // }

                .s-one {

                    .logo {
                        height: 50px;
                        margin-bottom: 17.5px;
                    }

                    h3 {
                        font-size: 14px;
                        font-weight: 600;
                        margin-bottom: 17.5px;
                    }

                    h4 {
                        font-size: 12px;
                        font-weight: 500;
                        margin-bottom: 3.5px;
                    }

                    ul {
                        padding-left: 0;
                        list-style: none;
                        margin: 9px 0 0 0;
                        display: inline-block;

                        li {
                            position: relative;
                            z-index: 0;
                            padding: 0 0 2.5px 19px;
                            font-size: 12px;
                            font-weight: 600;

                            .symbol {
                                position: absolute;
                                top: 1px;
                                left: 0;
                            }
                        }
                    }
                }

                .s-two {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: -2;

                    .illustration {
                        padding: 7px;

                        svg {
                            max-width: 60%;
                            height: auto;
                            margin: 0 auto;
                        }
                    }
                }

                // dark overlay
                &.dark-overlay {
                    &.true {
                        position: relative;
                        z-index: 0;

                        &::before {
                            background-color: rgba(0, 0, 0, 0.5);
                            content: " ";
                            z-index: -1 !important;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }

            }

            //form
            .form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                width: 243px;

                // @include media-breakpoint-xxl {
                //     width: 324px;
                // }

                h3 {
                    color: #181C32;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 36px;
                }

                .field {
                    margin-bottom: 22px;

                    .text {
                        height: 10px;
                        background-color: #F3F6F9;
                        width: 35%;
                        margin-bottom: 3px;
                    }

                    .input {
                        height: 28px;
                        background-color: #F3F6F9;
                        border-radius: 4px;
                    }
                }

                .btn {
                    margin-top: 18px;
                    width: 100%;
                }
            }



            // style
            &.style {

                // photo
                &.photo {
                    .details {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                // photo
                &.illustration {
                    .details {
                        .s-one {
                            padding-top: 44px;
                        }
                    }
                }

            }
        }
    }
}
