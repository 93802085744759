.Toastify__toast {
  border-radius: 10px;
  padding: 0 20px;
  text-align: center;
}
.Toastify__toast-body {
  font-weight: 500;
  font-size: 16px;
}
.Toastify__toast--success{
  background-color: #37db70 !important;
}
.Toastify__toast--error {
  background-color: #fa6160;
}